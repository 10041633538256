import { apiSlice } from "utils/rootApi/apiSlice";

const languageApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({


        getAllLangs: builder.query({
            query: () => {
                const url = `/public/language-stacks`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
            providesTags: ["langs"],
        }),


        getOneLang: builder.query({
            query: (id) => {
                const url = `/public/language-stack/${id}`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
            providesTags: ["langs"],
        }),


        addLanguage: builder.mutation({
            query: (body) => {
                const url = `/admin/language-stack/create`;
                const method = "POST";
                return {
                    url,
                    method,
                    body,
                };
            },
            invalidatesTags: ["langs"],
        }),

        editLang: builder.mutation({
            query: (rqBody) => {

                const url = `/admin/language-stack/update/${rqBody?.langId}`;
                const method = "PUT";


                const body = {
                    name: rqBody?.name,
                    parentId: rqBody?.parentId
                }

                return {
                    url,
                    method,
                    body,
                };
            },
            invalidatesTags: ["langs"],
        }),
    }),
});

export const { useGetAllLangsQuery, useAddLanguageMutation, useEditLangMutation, useGetOneLangQuery } = languageApi;
