import React, { useState } from "react";
import { useGetAllLangsQuery } from "utils/commonApi/languageApi";

const LanguageStacksSelectionEdit = ({ selectedLangs, setSelectedLangs }) => {
  const { data, isLoading: langsLoading } = useGetAllLangsQuery();

  const langs = data?.data;

  const [selectedLang, setSelectedLang] = useState("");

  const handleLangChange = (e) => {
    const langId = e.target.value;
    const langName = e.target.options[e.target.selectedIndex].text;

    if (!selectedLangs.includes(langId)) {
      setSelectedLangs([...selectedLangs, { id: langId, name: langName }]);
      setSelectedLang("");
    } // Add the missing closing parenthesis here
  };

  const handleRemoveLang = (lang) => {
    const updatedLangs = selectedLangs.filter(
      (selectedLang) => selectedLang.id !== lang.id
    );
    setSelectedLangs(updatedLangs);
  };
  if (langsLoading) return <h2>Loading...</h2>;

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">Language</span>
      </label>
      <div>
        <div className="border p-4 mb-4">
          {selectedLangs.map((lang, index) => (
            <span
              key={index}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
            >
              {lang.name}
              <span className="ml-2 cursor-pointer" onClick={() => handleRemoveLang(lang)}>
                X
              </span>
            </span>
          ))}
        </div>

        <select
          className="select select-primary w-full max-w-xs"
          value={selectedLang}
          onChange={handleLangChange}
        >
          <option disabled value="">
            Select a Language stack
          </option>
          {langs?.map((lang) => (
            <option key={lang?.id} value={lang?.id}>
              {lang?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LanguageStacksSelectionEdit;
