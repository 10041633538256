/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { baseUrl } from "./constants";
import { checkIsAuthLogin } from "./helper";

const defaults = () => ({
  baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`
  },
  error: {
    message: "Something went wrong. Please check your internet connection",
    statusCode: 503,
    data: {},
  },
});

const api = (method, url, variables) => {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${defaults().baseUrl}${url}`,
      headers: defaults().headers,
      data: variables ? variables : undefined,
    }).then(
      (response) => {
        // check for the auth api and store the authorization token to localhost
        if (checkIsAuthLogin(response)) {
          localStorage.setItem(
            "token",
            response?.headers.authorization?.split(" ")[1]
          );
        }
        if (100 <= response.status && 399 >= response.status) {
          resolve(response.data);
        } else {
          reject(response?.data);
        }
      },
      (error) => {
        reject(error?.response?.data);
      }
    );
  });
};




export default {
  post: (...args) => api("post", ...args),
  get: (...args) => api("get", ...args),
  put: (...args) => api("put", ...args),
  delete: (...args) => api("delete", ...args),
};
