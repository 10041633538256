import OpportunityInput from "components/opportunity/OpportunityInput";
import OpportunityTable from "components/opportunity/OpportunityTable";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetAllContributionsQuery } from "utils/commonApi/contributionApi";
import { useGetOneOsQuery } from "utils/commonApi/osnApi";

const AddOpportunityPage = () => {
  const { id } = useParams();

  const { data: result, isLoading: osnLoading } = useGetOneOsQuery(id);

  const osn = result?.data;

  const { data, isLoading } = useGetAllContributionsQuery(id);

  const opportunities = data?.data;

  if (osnLoading) {
    return <h2>Loading...</h2>;
  }
  return (
    <div>
      <OpportunityInput osnId={id} osn={osn}></OpportunityInput>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <OpportunityTable opportunities={opportunities}></OpportunityTable>
      )}
    </div>
  );
};

export default AddOpportunityPage;
