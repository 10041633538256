import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "utils/constants";

const VerifyUserPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(false);
  const [isVerified, setVerified] = useState(false);

  useEffect(() => {
    const verifyToken = async () => {
      setVerifying(true);

      try {
        const response = await axios.get(`${baseUrl}/auth/verify/${token}`);
        const result = response.data;
        if (result?.user?.data?.isVerified) {
          const token = result?.token;

          setVerifying(false);
          setVerified(true);
          localStorage.setItem("token", token);

        } else {
          setVerified(false);
          setVerifying(false);
        }
      } catch (error) {
        setVerified(false);
        setVerifying(false);
        console.log("Error verifying token:", error?.response.data);
      }
    };
    if (token) {
      verifyToken();
    }
  }, [token]);

  if (verifying)
    return (
      <h2 className="text-center py-10 text-2xl text-blue-500">
        Verifying user...
      </h2>
    );

  return (
    <div>
      {isVerified ? (
        <>
          <h2 className="text-center py-10 text-2xl text-green-500">
            Verification Successful
          </h2>
          <div className="w-full flex items-center justify-center">
            <button
              onClick={() => navigate("/")}
              className="btn-secondary btn text-white"
            >
              Go Homepage
            </button>
          </div>
        </>
      ) : (
        <h2 className="text-center py-10 text-2xl text-red-500">
          Verification Failed
        </h2>
      )}
    </div>
  );
};

export default VerifyUserPage;
