import { apiSlice } from "utils/rootApi/apiSlice";

const authApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({


        verifyUser: builder.query({
            query: (data) => {
                const url = `/auth/user`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
        }),

        magicLink: builder.mutation({
            query: (body) => {
                const url = `/auth/magic-link`;
                const method = "POST";

                return {
                    url,
                    method,
                    body
                };
            },
        }),


    }),
});

export const { useVerifyUserQuery, useMagicLinkMutation } = authApi;
