import React from "react";

const FZFPage = () => {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <h2 className="text-center text-red-600">
        The page you are looking for doesn't exist
      </h2>
    </div>
  );
};

export default FZFPage;
