import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "utils/commonApi/profileApi";

const SettingsPage = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useGetUserQuery();
  const profile = data?.data;

  if (isLoading) {
    return <>Loading..</>;
  }
  return (
    <div className="mx-auto w-10/12">
      <h2 className="text-left text-xl py-10">Profile</h2>
      <div className="flex flex-col mt-10 h-auto w-4/5 bg-gray-100 py-10">
        <div className="w-48 h-48 rounded-3 mb-10">
          <img
            className="w-full h-full"
            src={profile?.profileImage}
            alt="avatar"
          />
        </div>

        <h2 className="text-xl capitalize mb-10 ml-5">Name: {profile?.name}</h2>
        <h2 className="text-md mb-5 ml-5">Email: {profile?.email}</h2>
        <h2 className="text-md mb-5 ml-5">Role: {profile?.role}</h2>
        <h2 className="text-md mb-5 ml-5">
          Verified: {profile?.isVerified ? "Yes" : "No"}
        </h2>

        <button onClick={()=>navigate("/profile/update")} className="btn btn-primary w-4/5 mx-auto mt-10">Edit</button>
      </div>
    </div>
  );
};

export default SettingsPage;
