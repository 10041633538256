import OsnEdit from 'components/osn/OsnEdit';
import React from 'react';

const OSNEditPage = () => {
    return (
        <>
         <OsnEdit></OsnEdit>   
        </>
    );
};

export default OSNEditPage;