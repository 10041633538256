import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllOsQuery } from "utils/commonApi/osnApi";

const OsnTable = () => {
  const { data: response, isLoading } = useGetAllOsQuery();

  const navigate = useNavigate();


  if (isLoading) return <h3>Loading...</h3>;
  return (
    <div id="osn_all" className="overflow-x-auto px-2 py-2 shadow-sm rounded-md mt-20">
  <div className="table-responsive">
    <table className="table table-compact">
      {/* head */}
      <thead>
        <tr>
          <th className="p-2">Name</th>
          <th className="p-2">Edit</th>
          <th className="p-2">Claims</th>
          <th className="p-2">Add opportunity</th>
        </tr>
      </thead>
      <tbody>
        {response?.data?.map((o) => (
          <tr key={o?.id}>
            <td className="p-2">{o?.name}</td>
            <td className="p-2">
              <button
                onClick={() => navigate(`/osn_main/edit/${o?.id}`)}
                className="btn btn-primary btn-sm"
              >
                Edit
              </button>
            </td>
            <td className="p-2">
              <button
                disabled={o?.claims?.length === 0}
                onClick={() => navigate(`/claim/${o?.id}`)}
                className="btn btn-secondary btn-sm text-white"
              >
                Claims
              </button>
            </td>
            <td className="p-2">
              <button
                disabled={o?.claimed?.length === 0}
                onClick={() => navigate(`/opportunity/${o?.id}`)}
                className="btn btn-secondary btn-sm text-white"
              >
                Opportunity
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

  );
};

export default OsnTable;
