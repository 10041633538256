import React, { useEffect, useState } from "react";
import Description from "components/welcome/Description";
import LoginForm from "components/welcome/LoginForm";
import RegisterForm from "components/welcome/RegisterForm";
import { useVerifyUserQuery } from "utils/commonApi/authApi";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const [isShowLogin, showLogin] = useState(true);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const { isLoading, isSuccess } = useVerifyUserQuery();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      navigate("/");
    }
  }, [isLoading, isSuccess, navigate]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage: "url(assets/lake.jpg)",
      }}
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-neutral-content">
        <div className="max-w-5xl mx-auto px-8">
          <div className="flex items-center justify-center h-screen">
            <div className="bg-black bg-opacity-60 flex flex-row px-20 py-20">
              <div className="w-full md:w-5/12 hidden md:block relative">
                <Description></Description>
              </div>
              <div className="md:w-1/12">
                <div className="h-full w-1/5 bg-primary"></div>
              </div>

              <div className="w-full md:w-7/12 font-poppin relative md:px-0 ">
                {isShowLogin ? (
                  <LoginForm
                    email={email}
                    setEmail={setEmail}
                    showLogin={showLogin}
                  />
                ) : (
                  <RegisterForm showLogin={showLogin} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
