import React from "react";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <h2 className="text-center text-red-600">
        you have no permission to access this page
      </h2>
      <div className="w-full flex items-center justify-center h-20">
        <button className="btn btn-primary" onClick={() => navigate("/")}>
          Go back to homepage
        </button>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
