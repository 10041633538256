import React from "react";
import { Link, Outlet, } from "react-router-dom";
import { useVerifyUserQuery } from "utils/commonApi/authApi";
import { drawerItems_admin, drawerItems_superAdmin } from "utils/constants";

const HomePage = () => {

  const { data, isLoading } = useVerifyUserQuery();

  const user = data?.data;

  const handleLogout = () => {
    localStorage.removeItem("token");
    setTimeout(()=>{
      window.location.reload();
    }, 100)
  };

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div className="drawer lg:drawer-open bg-white">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <label
          htmlFor="my-drawer-2"
          className="btn btn-primary drawer-button lg:hidden"
        >
          Open drawer
        </label>
        {/* Page content here */}
        <div>
          <Outlet></Outlet>
        </div>
        {/* Page content here */}
      </div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer-2"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu p-4 w-64 min-h-full bg-secondary ">
          <span className="py-10 text-white"></span>
          {/* Sidebar content here */}
          {drawerItems_admin?.map((di, index) => (
            <li key={index} className={`text-xl py-4 text-white`}>
              <Link to={di.link}>
                <span>{di.icon}</span>
                <span>{di?.label}</span>
              </Link>
            </li>
          ))}
          {user?.role === "SUPER_ADMIN" &&
            drawerItems_superAdmin?.map((di, index) => (
              <li key={index} className="text-xl py-4 text-white">
                <Link to={di.link}>
                  <span>{di.icon}</span>
                  <span>{di?.label}</span>
                </Link>
              </li>
            ))}

          <button
            onClick={() => handleLogout()}
            className="text-white btn btn-primary pb-0"
          >
            Log out
          </button>
        </ul>
      </div>
    </div>
  );
};

export default HomePage;
