import TagsEdit from 'components/tags/TagsEdit';
import React from 'react';

const TagsEditPage = () => {
    return (
        <>
         <TagsEdit></TagsEdit>   
        </>
    );
};

export default TagsEditPage;