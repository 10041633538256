import axios from "axios";
import React, { useEffect } from "react";
import { baseUrl } from "utils/constants";

const ImageFileInput = ({ featuredImageFile, setFeaturedImageFile,imgUrl, setImgURl }) => {
  const uploadImage = async (formData) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    };
    return axios.post(`${baseUrl}/admin/upload`, formData, { headers });
  };

  useEffect(() => {
    const uploadImageAsync = async () => {
      if (featuredImageFile) {
        const formData = new FormData();
        formData.append("file", featuredImageFile);

        try {
          const response = await uploadImage(formData);
          setImgURl(response?.data?.Location);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    };

    if (featuredImageFile?.name) {
      uploadImageAsync();
    }
  }, [featuredImageFile, setImgURl]);
  return (
    <div className="w-full ">
      <label className="label">
        <span className="label-text">Featured image</span>
      </label>
      <input
        type="file"
        placeholder="featured Image"
        className="file-input file-input-bordered w-full max-w-xs"
        required={!imgUrl}
        name="featuredImage"
        onChange={(e) => setFeaturedImageFile(e.target.files[0])}
      />
    </div>
  );
};

export default ImageFileInput;
