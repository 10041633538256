import { apiSlice } from "utils/rootApi/apiSlice";

const articleApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllArticles: builder.query({
            query: () => {
                const url = `/public/articles/0`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
            providesTags: ["articles"],
        }),

        getOneArticle: builder.query({
            query: (id) => {
                const url = `/public/article/${id}`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
            providesTags: ["articles"],
        }),


        addArticle: builder.mutation({
            query: (body) => {
                const url = `/admin/article/create`;
                const method = "POST";


                return {
                    url,
                    method,
                    body,
                };
            },
            invalidatesTags: ["articles"],
        }),

        editArticle: builder.mutation({
            query: (body) => {
                const url = `/admin/article/update/${body?.id}`;
                const method = "PUT";
                delete body.id;
                return {
                    url,
                    method,
                    body,
                };
            },
            invalidatesTags: ["articles"]
        }),
    }),
});

export const { useGetAllArticlesQuery, useGetOneArticleQuery, useEditArticleMutation, useAddArticleMutation } = articleApi;
