import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  useEditLangMutation,
  useGetAllLangsQuery,
  useGetOneLangQuery,
} from "utils/commonApi/languageApi";

const LanguageStackEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllLangsQuery();

  const langs = data?.data;

  const { data: langData, isLoading: langLoading } = useGetOneLangQuery(id);

  const lang = langData?.data;

  const [editLanguage, { isLoading: langEditing }] = useEditLangMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: e.target.name.value,
      parentId: Number(e.target.parentId.value),
      langId: id,
    };

    const result = await editLanguage(data);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
      navigate("/language_stacks_main");
    }
  };

  if (langLoading || isLoading) return <h2>Loading...</h2>;
  return (
    <form
  className="mx-auto w-10/12 p-5 shadow-md border-y-2 border-primary mt-20"
  onSubmit={handleSubmit}
>
  <h2 className="text-2xl py-10">Language Stack Edit</h2>

  <div className="flex flex-col md:flex-row items-center">
    <input
      defaultValue={lang?.name}
      name="name"
      className="input input-bordered input-primary w-full max-w-xs mb-5 md:mr-5 md:mb-0"
    />
    <select
      className="select select-bordered w-full max-w-xs"
      name="parentId"
      id=""
    >
      <option selected value={0}>
        none
      </option>
      {langs?.map((la) => (
        <option key={la?.id} value={la?.id}>
          {la?.name}
        </option>
      ))}
    </select>
  </div>
  <button disabled={langEditing} className="btn btn-primary w-full mt-5">
    Submit
  </button>
  <ToastContainer></ToastContainer>
</form>

  );
};

export default LanguageStackEdit;
