import { apiSlice } from "utils/rootApi/apiSlice";

const osnApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOs: builder.query({
      query: () => {
        const url = `/admin/open-sources/0`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["osn"],
    }),

    getOneOs: builder.query({
      query: (id) => {
        const url = `/public/open-source/${id}`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["osn"],
    }),


    addOsn: builder.mutation({
      query: (body) => {
        const url = `/admin/open-source/create`;
        const method = "POST";


        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["osn"],
    }),

    editOs: builder.mutation({
      query: (body) => {
        const url = `/admin/open-source/update/${body?.id}`;
        const method = "PUT";
        delete body.id;

        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["osn"]
    }),
  }),
});

export const { useGetAllOsQuery, useGetOneOsQuery, useEditOsMutation, useAddOsnMutation } = osnApi;
