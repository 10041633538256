import React, { useState } from "react";
import { useGetAllTagsQuery } from "utils/commonApi/tagsApi";

const TagsSelection = ({ selectedTags, setSelectedTags }) => {
  const { data: result, isLoading: tagsLoading } = useGetAllTagsQuery();
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagChange = (e) => {
    const tagId = e.target.value;
    const tagName = e.target.options[e.target.selectedIndex].text;

    if (!selectedTags.includes(tagId)) {
      setSelectedTags([...selectedTags, { id: tagId, name: tagName }]);
      setSelectedTag("");
    } // Add the missing closing parenthesis here
  };

  const handleRemoveTag = (tag) => {
    const updatedTags = selectedTags.filter(
      (selectedTag) => selectedTag.id !== tag.id
    );
    setSelectedTags(updatedTags);
  };
  if (tagsLoading) return <h2>Loading...</h2>;
  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">Tags</span>
      </label>
      <div>
        <div className="border p-4 mb-4">
          {selectedTags.map((tag, index) => (
            <span
              key={index}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
            >
              {tag.name}
              <span
                className="ml-2 cursor-pointer"
                onClick={() => handleRemoveTag(tag)}
              >
                X
              </span>
            </span>
          ))}
        </div>

        <select
          className="select select-primary w-full max-w-xs"
          value={selectedTag}
          onChange={handleTagChange}
        >
          <option disabled value="">
            Select a tag
          </option>
          {result?.data?.map((tag) => (
            <option key={tag?.id} value={tag?.id}>
              {tag?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TagsSelection;
