import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useManageUserMutation } from "utils/commonApi/manageUserApi";
import { userRoles } from "utils/constants";

const UserTable = ({ users }) => {
  const [roleData, setRoleData] = useState({
    role: "",
    id: "",
  });


  const [manageUser, { isLoading }] = useManageUserMutation();
  const handleRoleChange = async () => {
    const isConfirmed = window.confirm("Are you sure you want to do this?");
    if (isConfirmed) {
      const result = await manageUser(roleData);

      if (result?.data?.success) {
        toast.success(result.data.message);
      } else {
        toast.error(
          result?.data?.message ? result?.data?.message : "Something went wrong"
        );
      }
    }
  };

  return (
    <div className="overflow-x-auto py-5 px-5 shadow-sm rounded-md mt-20">
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>SL</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Change role</th>
              <th>Apply</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, i) => (
              <tr key={i}>
                <th>{i + 1}</th>
                <td>{user?.name}</td>
                <td>{user?.email}</td>
                <td>{user?.role}</td>
                <td>
                  <select
                    disabled={user.role === "SUPER_ADMIN"}
                    onChange={(e) =>
                      setRoleData({
                        role: e.target.value,
                        id: user?.id,
                      })
                    }
                    className="select select-bordered w-full max-w-xs"
                    defaultValue={"DEFAULT"}
                  >
                    <option value={"DEFAULT"} disabled>
                      {user?.role}
                    </option>
                    {userRoles?.map((r) => (
                      <option key={r} value={r}>
                        {r}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <button
                    onClick={() => handleRoleChange(user?.id)}
                    disabled={
                      roleData?.role === "" ||
                      roleData?.role === user?.role ||
                      user?.id !== roleData?.id ||
                      user?.role === "SUPER_ADMIN" ||
                      isLoading
                    }
                    className="btn btn-active btn-neutral"
                  >
                    Apply
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default UserTable;
