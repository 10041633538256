import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "utils/constants";

const MediaJsonEdit = ({
  handleAddMediaToMediaArray,
  mediaArray,
  setMediaArray,
}) => {
  const [imgFile, setImgFile] = useState(null);

  const [media, setMedia] = useState({
    name: "",
    url: "",
    type: "YOUTUBE",
  });

  const [isAdding, setIsAdding] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMedia((prevMedia) => ({ ...prevMedia, [name]: value }));
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      };
      const response = await axios.post(`${baseUrl}/admin/upload`, formData, {
        headers,
      });

      const imgUrl = await response.data.Location;
      return imgUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleAddClick = async () => {
    setIsAdding(true);

    if (media.type === "IMAGE" && imgFile) {
      const imgUrl = await uploadFile(imgFile);
      if (imgUrl) {
        if (imgUrl) {
          setMedia((prevMedia) => ({ ...prevMedia, url: imgUrl }));
          handleAddMediaToMediaArray({ ...media, url: imgUrl }); // Pass the updated state directly
        }
      } else {
        alert("something went wrong");
      }
    } else if (media.type === "YOUTUBE" && media?.url) {
      handleAddMediaToMediaArray(media);
    }

    setMedia({ name: "", url: "", type: "YOUTUBE" });
    setImgFile(null);

    setIsAdding(false);
  };

  const handleRemoveClick = (index) => {
    const updatedMediaArray = [...mediaArray];
    updatedMediaArray.splice(index, 1);
    setMediaArray(updatedMediaArray);
  };

  const addDisable = !imgFile && !media.url ? true : false;

  return (
    <div className="form-control mb-5">
  <label className="label">
    <span className="label-text">Media json</span>
  </label>

  {mediaArray.map((media, i) => (
    <div key={i} className="flex flex-col md:flex-row items-center mb-3 md:mb-0">
      <span className="mr-5">{media.name}</span>
      <span className="mr-5">{media.type}</span>
      {media?.type === "IMAGE" && (
        <span className="w-[60px] h-[60px] mr-5">
          <img className="w-full h-full" src={media?.url} alt="" />
        </span>
      )}
      {media?.type === "YOUTUBE" && <span>{media.url}</span>}
      <button
        type="button"
        onClick={() => handleRemoveClick(i)}
        className="btn btn-error mt-3 md:mt-0"
      >
        Remove
      </button>
    </div>
  ))}

  <div className="flex flex-col md:flex-row items-center mt-5">
    <input
      name="name"
      type="text"
      className="input input-bordered mb-3 md:mr-5"
      placeholder="name"
      value={media.name}
      onChange={handleInputChange}
      disabled={isAdding}
    />
    <select
      name="type"
      className="select select-bordered mb-3 md:mr-5"
      value={media.type}
      onChange={handleInputChange}
      disabled={isAdding}
    >
      <option value="YOUTUBE">YOUTUBE</option>
      <option value="IMAGE">IMAGE</option>
    </select>
    {media.type === "IMAGE" ? (
      <input
        name="url"
        type="file"
        className="file-input file-input-bordered w-full max-w-xs mb-3 md:mr-5"
        onChange={(e) => setImgFile(e.target.files[0])}
        disabled={isAdding}
      />
    ) : (
      <input
        name="url"
        type="text"
        className="input input-bordered mb-3 md:mr-5"
        placeholder="url"
        value={media.url}
        onChange={handleInputChange}
        disabled={isAdding}
      />
    )}
    <button
      className="btn btn-primary w-full md:w-auto"
      onClick={handleAddClick}
      disabled={isAdding || !media?.name || addDisable}
    >
      Add
    </button>
  </div>
</div>

  );
};

export default MediaJsonEdit;
