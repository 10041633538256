import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from 'utils/constants'

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("token") || "";
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }

            return headers;
        }

    }),
    tagTypes: ["profile", "cats", "tags", "langs", "osn", "articles", "opportunities", "users"],
    endpoints: (build) => ({})
})
