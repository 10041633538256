import { apiSlice } from "utils/rootApi/apiSlice";
// this file renamed as contribution api due to the error module not found. the previous name was opportunityApi. 

const contributionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getAllContributions: builder.query({
      query: (osnId) => {
        const url = `/public/${osnId}/opportunities/0`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["opportunities"],
    }),

    getApplications: builder.query({
      query: (opportunityId) => {
        // 0 is the page number here in the url, providing 0 will result retrieving all the data. If you replace with it any positive number, you will have the data based on the page number. 
        const url = `/admin/${opportunityId}/applications/0`;
        const method = "GET";
        return {
          url,
          method,
        };
      },
      providesTags: ["opportunities"],
    }),

    getOneContribution: builder.query({
      query: (opportunityId) => {

        const url = `/public/opportunity/${opportunityId}`;
        const method = "GET";


        return {
          url,
          method,
        };
      },
      providesTags: ["opportunities"],
    }),

    addContribution: builder.mutation({
      query: (body) => {
        const url = `/admin/opportunity/create`;
        const method = "POST";
        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["opportunities"],
    }),

    updateContribution: builder.mutation({
      query: (body) => {
        const url = `/admin/opportunity/update/${body?.opportunityId}`;
        const method = "PUT";
        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["opportunities"],
    }),


  }),
});

export const { useGetAllContributionsQuery, useAddContributionMutation, useGetOneContributionQuery, useGetApplicationsQuery, useUpdateContributionMutation } = contributionApi;
