import React from "react";
import { useNavigate } from "react-router-dom";

const TagsTable = ({ tags }) => {
  const navigate = useNavigate();
  return (
    <table className="table w-10/12 mx-auto mt-10">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">TagName</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {tags?.map((tag, i) => (
          <tr key={tag?.id}>
            <th scope="row">{i + 1}</th>

            <td>{tag?.name}</td>

            <td>
              <button
                onClick={() => navigate(`/tags_main/edit/${tag?.id}`)}
                type="button"
                className="btn btn-primary"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TagsTable;
