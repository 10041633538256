import { apiSlice } from "utils/rootApi/apiSlice";

const manageUserApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getAllUsers: builder.query({
            query: (page) => {
                const url = `/admin/users/${page}`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
            providesTags: ['users']
        }),

        manageUser: builder.mutation({
            query: (data) => {
                const url = `/admin/manage/${data?.id}`;
                const method = "PUT";

                return {
                    url,
                    method,
                    body: {
                        role: data?.role
                    }
                };
            },
            invalidatesTags: ['users']

        }),




        magicLink: builder.mutation({
            query: (body) => {
                const url = `/auth/magic-link`;
                const method = "POST";

                return {
                    url,
                    method,
                    body
                };
            },
        }),


    }),
});

export const { useMagicLinkMutation, useManageUserMutation, useGetAllUsersQuery } = manageUserApi;
