import UserTable from "components/manageUser/UserTable";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllUsersQuery } from "utils/commonApi/manageUserApi";

const ManageUsersPage = () => {
  const params = useParams();
  const page = Number(params?.page);
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllUsersQuery(page);
  const users = data?.data;

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      {/* hello */}
      <UserTable users={users}></UserTable>
      <div className="w-11/12 mx-auto flex justify-between items-center mt-10">
        <button
          onClick={() => navigate(`/manage_users_main/${page - 1}`)}
          className="btn btn-primary text-white"
          disabled={page === 1}
        >
          Prev
        </button>
        <button
          onClick={() => navigate(`/manage_users_main/${page + 1}`)}
          className="btn btn-primary text-white"
          disabled={users?.length !== 10}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ManageUsersPage;
