import ImageFileInput from "components/others/ImgageFileInput";
import MyTextEditor from "components/others/MyTextEditor";
import TagsSelection from "components/others/TagsSelection";
import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAddArticleMutation } from "utils/commonApi/articleApi";

const ArticleInput = () => {
  const [featuredImageFile, setFeaturedImageFile] = useState(null);
  const [imgUrl, setImgURl] = useState("");

  const [isFeatured, setFeatured] = useState(false);

  const [isPublished, setPublished] = useState(true);

  //   configuring select tag feature
  const [selectedTags, setSelectedTags] = useState([]);

  const [addArticle, { isLoading: articleLoading }] = useAddArticleMutation();

  const desRef = useRef(null);
  const [content, setContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalTags = selectedTags?.map((item) => Number(item.id));
    const title = e.target.title.value;
    const featuredImage = imgUrl;
    const excerpt = e.target.excerpt.value;
    // const featuredImage = "https://dummyimage.com/600x400/000/fff";

    if (!featuredImage) {
      return toast.error("Featured image is mandatory");
    }

    if (finalTags?.length < 0) {
      return toast.error("Please add at least one tag");
    }

    const data = {
      title,
      featuredImage,
      isPublished,
      isFeatured,
      content,
      excerpt,
      tags: finalTags,
    };

    const result = await addArticle(data);

    if (result?.data?.success) {
      toast.success(result?.data?.message);
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="px-10 py-10 my-10 mx-auto w-10/12 shadow-xl rounded-md border-secondary border-y-2 mb-5"
      >
        <div className="w-full mx-auto flex items-center justify-between">
          <span className="text-2xl text-black">Add Article</span>
          <a href="#article_all" className="text-blue-500 text-md">
            View all
          </a>
        </div>
        
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Title</span>
          </label>
          <input
            type="text"
            placeholder="title"
            className="input input-bordered"
            required
            name="title"
          />
        </div>
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Excerpt</span>
          </label>
          <textarea
            type="text"
            placeholder="excerpt"
            className="input input-bordered h-40"
            required
            name="excerpt"
          />
        </div>
        <div className="form-control h-auto flex flex-row items-center mb-5 w-3/5">
          <ImageFileInput
            featuredImageFile={featuredImageFile}
            setFeaturedImageFile={setFeaturedImageFile}
            setImgURl={setImgURl}
          ></ImageFileInput>
          {imgUrl && (
            <div className="ml-10 w-1/5 h-[100px] bg-red-500">
              <img className="w-full h-full " src={imgUrl} alt="img_normal" />
            </div>
          )}
        </div>

        <div className="form-control mb-5 h-fit">
          <label className="label">
            <span className="label-text">Content</span>
          </label>

          <MyTextEditor
            article={true}
            isRef={desRef}
            content={content}
            setContent={setContent}
          ></MyTextEditor>
        </div>

        <div className="h-[200px]"></div>

        <TagsSelection
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        ></TagsSelection>

        <div className=" flex items-center">
          <input
            onChange={() => setPublished(!isPublished)}
            name="isActive"
            type="checkbox"
            className="my-3 checkbox"
            defaultChecked={isPublished}
          />

          <label className="label ml-3">
            <span className="label-text">Published</span>
          </label>
        </div>
        <div className=" flex items-center mr-10">
          <input
            onChange={() => setFeatured(!isFeatured)}
            name="isActive"
            type="checkbox"
            className="my-3 checkbox"
            defaultChecked={isFeatured}
          />

          <label className="label ml-3">
            <span className="label-text">Featured</span>
          </label>
        </div>

        <button
          disabled={articleLoading}
          className="btn btn-primary w-full mt-3"
        >
          Submit
        </button>
      </form>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default ArticleInput;
