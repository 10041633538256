import React from "react";

const ApplicantsTable = ({ applications }) => {
  console.log(applications)
  return (
    <div
      id="osn_all"
      className="overflow-x-auto py-5 px-5 shadow-sm rounded-md mt-20"
    >
      <table className="table">
        <thead>
          <tr>
            <th>Applicant name</th>
            <th>Applicant Email</th>
            <th>Letter</th>
            <th>Public Profile</th>
          </tr>
        </thead>
        <tbody>
          {applications?.map((o) => (
            <tr key={o?.id}>
              <th>{o?.name}</th>
              <td>{o?.email}</td>
              <td>{o?.proposalLetter}</td>
              <td>
                <a
                  href={o?.publicProfile}
                  rel="noreferrer"
                  target="_blank"
                  
                  className="btn btn-primary"
                >
                  Public Profile
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApplicantsTable;
