import api from "utils/api";
import * as dayjs from 'dayjs';

export const checkIsAuthLogin = (data) => {
  const arr = data?.config?.url.split("/");
  if ((arr[arr.length - 1] === ("login"))||(arr[arr.length - 2] === ("verify"))) {
    return true;
  } else {
    return false;
  }
};

export const fetcher = (url) => {
  return api.get(url);
};

export const remove_ = (str) => str.replace(/_/g, " ");
export const add_ = (str) => str.replace(/ /g, "_");

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const dangerousHtmlMarkup = (value) => {
  return { __html: value };
}

// error handler for swr

export const globleErrorHandler = (err) => {
  console.log(err)
  if (err.statusCode === 401) {
    localStorage.removeItem('token')
    window.location.href = window.location.origin + '/login'
  }
}

export const timeAgo = (timestamp) => {
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
  const currentTime = dayjs();
  const timeAgo = dayjs(timestamp).from(currentTime);
  return timeAgo;
}


