import { apiSlice } from "utils/rootApi/apiSlice";

const feedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTags: builder.query({
      query: () => {
        const url = `/public/tags`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["tags"],
    }),

    getOneTag: builder.query({
      query: (tagId) => {
        const url = `/public/tag/${tagId}`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["tags"],
    }),


    addTag: builder.mutation({
      query: (body) => {
        const url = `/admin/tag/create`;
        const method = "POST"
        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["tags"]
    }),


    editTag: builder.mutation({
      query: (body) => {
        const url = `/admin/tag/update/${body?.tagId}`;
        const method = "PUT";
        const requestBody = {
          name: body.name
        };

        return {
          url,
          method,
          body: requestBody,
        };
      },
      invalidatesTags: ["tags"]
    }),
  }),
});

export const { useGetAllTagsQuery, useEditTagMutation, useGetOneTagQuery, useAddTagMutation } = feedApi;
