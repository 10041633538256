import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useEditOsMutation, useGetOneOsQuery } from "utils/commonApi/osnApi";
import ImageFileInput from "components/others/ImgageFileInput";
import MediaJsonEdit from "components/others/MediaJsonEdit";
import MyTextEditor from "components/others/MyTextEditor";
import TagsSelectionEdit from "components/others/TagsSelectionEdit";
import CategoriesSelectionEdit from "components/others/CategoriesSelectionEdit";
import LanguageStacksSelectionEdit from "components/others/LanguageStacksSelectionEdit";
import { projectUrlPattern } from "utils/constants";

const OsnEdit = () => {
  const [featuredImageFile, setFeaturedImageFile] = useState([]);
  const [imgUrl, setImgURl] = useState("");

  const { osnId } = useParams();

  const { data: result, isLoading: osnLoading } = useGetOneOsQuery(osnId);

  const osn = result?.data;

  const [editData, { isLoading: isEditing }] = useEditOsMutation();

  const desRef = useRef(null);
  const [description, setDescription] = useState(osn?.description);

  const depRef = useRef(null);
  const [deploymentInstructions, setDeploymentInstructions] = useState(
    osn?.deploymentInstructions
  );

  const [isActive, setActive] = useState(false);
  const [isClaimed, setClaimed] = useState(false);
  const [isFeatured, setFeatured] = useState(false);
  const [isCodeQuestEnabled, setCodeQuestEnabled] = useState(false);

  useEffect(() => {
    if (osn) {
      const isJson = isJSONString(osn?.mediaJson);

      let parsedMediaArr;

      if (isJson) {
        parsedMediaArr = JSON.parse(osn?.mediaJson)?.media;
      } else {
        parsedMediaArr = [];
      }

      setDescription(osn?.description);
      setDeploymentInstructions(osn?.deploymentInstructions);

      setActive(osn?.isActive);
      setClaimed(osn?.isClaimed);
      setCodeQuestEnabled(osn?.isCodeQuestEnabled);
      setFeatured(osn?.isFeatured);
      setImgURl(osn?.featuredImage);

      setSelectedTags([...osn?.tags]);
      setSelectedCats([...osn?.categories]);
      setSelectedLangs([...osn?.language_stacks]);
      setMediaArray([...parsedMediaArr]);
    }
  }, [osn]);

  //   configuring select tag feature
  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedLangs, setSelectedLangs] = useState([]);

  const [selectedCats, setSelectedCats] = useState([]);
  const [mediaArray, setMediaArray] = useState([]);

  const handleAddMediaToMediaArray = (media) => {
    setMediaArray((prevMediaArray) => [...prevMediaArray, media]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalTags = selectedTags?.map((item) => Number(item.id));
    const finalCats = selectedCats?.map((item) => Number(item.id));
    const finalLangs = selectedLangs?.map((item) => Number(item.id));

    const name = e.target.title.value;
    const featuredImage = imgUrl;

    const tagline = e.target.tagline.value;
    const license = e.target.license.value;

    const projectUrl = e.target.projectUrl.value;

    // if (!projectUrlPattern.test(projectUrl)) {
    //   return toast.error("Incorrect projectUrl");
    // }

    if (!featuredImage) {
      return toast.error("Featured image is mandatory");
    }
    if (
      finalTags?.length <= 0 ||
      finalLangs?.length <= 0 ||
      finalCats?.length <= 0
    ) {
      return toast.error("Please add at least one tag, category and language");
    }

    const data = {
      id: osn?.id,
      name,
      projectUrl,
      featuredImage,
      tagline,
      license,
      mediaJson: JSON.stringify({ media: mediaArray }),
      description,
      deploymentInstructions,
      tags: finalTags,
      language_stacks: finalLangs,
      categories: finalCats,
      isActive,
      isFeatured,
      isClaimed,
      isCodeQuestEnabled,
    };

    const result = await editData(data);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
    } else {
      toast.error(
        result?.error?.data?.message
          ? result?.error?.data?.message
          : "something went wrong"
      );
    }
  };

  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }
  if (osnLoading) return <div>Loading...</div>;

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="px-4 py-4 my-4 mx-auto w-full md:w-10/12 shadow-xl rounded-md border-secondary border-y-2 mb-5"
      >
        <span className="text-2xl text-black">Edit open source</span>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            placeholder="name"
            className="input input-bordered"
            required
            name="title"
            defaultValue={osn?.name}
          />
        </div>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Project url</span>
          </label>
          <input
            type="text"
            placeholder="Project URL"
            className="input input-bordered"
            required
            name="projectUrl"
            defaultValue={osn?.projectUrl}
          />
        </div>

        <div className="form-control h-auto flex flex-row items-center mb-5 w-3/5">
          <ImageFileInput
            featuredImageFile={featuredImageFile}
            setFeaturedImageFile={setFeaturedImageFile}
            setImgURl={setImgURl}
            imgUrl={imgUrl}
          ></ImageFileInput>
          {imgUrl && (
            <div className="ml-10 w-1/5 h-[100px] bg-red-500">
              <img className="w-full h-full " src={imgUrl} alt="img_normal" />
            </div>
          )}
        </div>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Tagline</span>
          </label>
          <input
            type="text"
            placeholder="tagline"
            className="input input-bordered"
            name="tagline"
            required
            defaultValue={osn?.tagline}
          />
        </div>

        <MediaJsonEdit
          defaultMedia={osn?.jsonMedia}
          handleAddMediaToMediaArray={handleAddMediaToMediaArray}
          mediaArray={mediaArray}
          setMediaArray={setMediaArray}
        ></MediaJsonEdit>

        <div className="form-control mb-5 h-fit">
          <label className="label">
            <span className="label-text">Description</span>
          </label>

          <MyTextEditor
            isRef={desRef}
            content={description}
            setContent={setDescription}
          ></MyTextEditor>
        </div>
        <div className="h-[200px]"></div>

        <div className="form-control mb-5 h-fit">
          <label className="label">
            <span className="label-text">Deployment instruction</span>
          </label>

          <MyTextEditor
            isRef={depRef}
            content={deploymentInstructions}
            setContent={setDeploymentInstructions}
          ></MyTextEditor>
        </div>
        <div className="h-[200px]"></div>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">License</span>
          </label>
          <input
            name="license"
            type="text"
            className="input input-bordered"
            required
            defaultValue={osn?.license}
          />
        </div>
        <TagsSelectionEdit
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        ></TagsSelectionEdit>

        <CategoriesSelectionEdit
          selectedCats={selectedCats}
          setSelectedCats={setSelectedCats}
        ></CategoriesSelectionEdit>

        <LanguageStacksSelectionEdit
          selectedLangs={selectedLangs}
          setSelectedLangs={setSelectedLangs}
        ></LanguageStacksSelectionEdit>

        <div className="py-10 flex items-center">
          <div className=" flex items-center mr-10">
            <input
              onChange={() => setActive(!isActive)}
              name="isActive"
              type="checkbox"
              className="my-3 checkbox"
              checked={isActive}
            />

            <label className="label ml-3">
              <span className="label-text">Active</span>
            </label>
          </div>

          <div className=" flex items-center mr-10">
            <input
              onChange={() => setClaimed(!isClaimed)}
              name="isClaimed"
              type="checkbox"
              className="my-3 checkbox"
              checked={isClaimed}
            />

            <label className="label ml-3">
              <span className="label-text">Claimed</span>
            </label>
          </div>

          <div className=" flex items-center mr-10">
            <input
              onChange={() => setCodeQuestEnabled(!isCodeQuestEnabled)}
              name="isCodeQuestEnabled"
              type="checkbox"
              className="my-3 checkbox"
              checked={isCodeQuestEnabled}
            />

            <label className="label ml-3">
              <span className="label-text">Quest enabled</span>
            </label>
          </div>

          <div className=" flex items-center mr-10">
            <input
              onChange={() => setFeatured(!isFeatured)}
              name="isFeatured"
              type="checkbox"
              className="my-3 checkbox"
              checked={isFeatured}
            />

            <label className="label ml-3">
              <span className="label-text">Featured</span>
            </label>
          </div>
        </div>

        <button disabled={isEditing} className="btn btn-primary w-full mt-3">
          Submit
        </button>
      </form>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default OsnEdit;
