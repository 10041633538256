import LanguageStackEdit from 'components/languageStacks/LanguageStackEdit';
import React from 'react';

const LanguageStackEditPage = () => {
    return (
        <>
         <LanguageStackEdit></LanguageStackEdit>   
        </>
    );
};

export default LanguageStackEditPage;