import ApplicantsTable from "components/applicants/ApplicantsTable";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetApplicationsQuery } from "utils/commonApi/contributionApi";

const ApplicantsPage = () => {
  const { opportunityId } = useParams();
  const { data, isLoading } = useGetApplicationsQuery(opportunityId);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  const applications = data?.data;

  return <ApplicantsTable applications={applications}></ApplicantsTable>;
};

export default ApplicantsPage;
