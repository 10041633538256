import CategoriesEdit from 'components/categories/CategoriesEdit';
import React from 'react';

const CategoriesEditPage = () => {
    return (
        <>
         <CategoriesEdit></CategoriesEdit>   
        </>
    );
};

export default CategoriesEditPage;