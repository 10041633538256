import LanguageStacksInput from "components/languageStacks/LanguageStacksInput";
import LanguageStacksTable from "components/languageStacks/LanguageStacksTable";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  useAddLanguageMutation,
  useGetAllLangsQuery,
} from "utils/commonApi/languageApi";

const LanguageStackMainPage = () => {
  const { data, isLoading: languagesLoading } =
    useGetAllLangsQuery();


    const languages = data?.data;

  const [addLanguage, { isLoading: languageAdding }] = useAddLanguageMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: e.target.name.value,
      parentId: Number(e.target.parentId.value),
    };

    const result = await addLanguage(data);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
    }
  };

  if (languagesLoading) return <h2>Loading...</h2>;
  return (
    <>
      <LanguageStacksInput
        handleSubmit={handleSubmit}
        languages={languages}
        languageAdding={languageAdding}
      ></LanguageStacksInput>
      <LanguageStacksTable languages={languages}></LanguageStacksTable>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default LanguageStackMainPage;
