import ImageFileInput from "components/others/ImgageFileInput";
import MyTextEditor from "components/others/MyTextEditor";
import TagsSelectionEdit from "components/others/TagsSelectionEdit";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  useEditArticleMutation,
  useGetOneArticleQuery,
} from "utils/commonApi/articleApi";

const ArticleInputEdit = () => {
  const [featuredImageFile, setFeaturedImageFile] = useState([]);
  const [imgUrl, setImgURl] = useState("");

  const { articleId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading: articleLoading } = useGetOneArticleQuery(articleId);

  const article = data?.data;

  const [editData, { isLoading: isEditing }] = useEditArticleMutation();

  const [isPublished, setPublished] = useState(article?.isPublished);
  const [isFeatured, setFeatured] = useState(false);

  const contentRef = useRef(null);
  const [content, setContent] = useState(article?.content);

  useEffect(() => {
    if (article) {
      setSelectedTags([...article?.tags]);
      setPublished(article?.isPublished);
      setFeatured(article?.isFeatured);
      setContent(article?.content);
      setImgURl(article?.featuredImage);
    }
  }, [article]);

  //   configuring select tag feature
  const [selectedTags, setSelectedTags] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalTags = selectedTags?.map((item) => Number(item.id));

    const title = e.target.title.value;
    const excerpt = e.target.excerpt.value;

    const featuredImage = imgUrl;

    if (!featuredImage) {
      return toast.error("Featured image is mandatory");
    }

    if (finalTags?.length < 0) {
      return toast.error("Please add at least one tag");
    }
    const data = {
      id: article?.id,
      title,
      featuredImage,
      content,
      tags: finalTags,
      isPublished,
      isFeatured,
      excerpt
    };
    const result = await editData(data);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
    }
  };

  if (articleLoading) return <div>Loading...</div>;

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="px-10 py-10 my-10 mx-auto w-10/12 shadow-xl rounded-md border-secondary border-y-2 mb-5"
      >
        <span className="text-2xl text-black mb-5">Edit article</span>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Title</span>
          </label>
          <input
            type="text"
            placeholder="title"
            className="input input-bordered"
            required
            name="title"
            defaultValue={article?.title}
          />
        </div>
        <div className="form-control mb-5 ">
          <label className="label">
            <span className="label-text">Excerpt</span>
          </label>
          <textarea
            type="text"
            placeholder="excerpt"
            className="input input-bordered h-40"
            required
            name="excerpt"
            defaultValue={article?.excerpt}
          />
        </div>

        <div className="form-control h-auto flex flex-row items-center mb-5 w-3/5">
          <ImageFileInput
            imgUrl={imgUrl}
            featuredImageFile={featuredImageFile}
            setFeaturedImageFile={setFeaturedImageFile}
            setImgURl={setImgURl}
          ></ImageFileInput>
          {imgUrl && (
            <div className="ml-10 w-1/5 h-[100px] bg-red-500">
              <img className="w-full h-full " src={imgUrl} alt="img_normal" />
            </div>
          )}
        </div>
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">content</span>
          </label>

          <MyTextEditor
            article={true}
            isRef={contentRef}
            content={content}
            setContent={setContent}
          ></MyTextEditor>
        </div>
        <div className="h-[200px]"></div>

        <TagsSelectionEdit
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        ></TagsSelectionEdit>

        <div className=" flex items-center">
          <input
            name=""
            type="checkbox"
            className="my-3 checkbox"
            checked={isPublished}
            onChange={() => setPublished((prevPublished) => !prevPublished)}
          />

          <label className="label ml-3">
            <span className="label-text">Published</span>
          </label>
        </div>

        <div className=" flex items-center">
          <input
            name=""
            type="checkbox"
            className="my-3 checkbox"
            checked={isFeatured}
            onChange={() => setFeatured(!isFeatured)}

          />

          <label className="label ml-3">
            <span className="label-text">Featured</span>
          </label>
        </div>

        <button disabled={isEditing} className="btn btn-primary w-full mt-3">
          Submit
        </button>
      </form>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default ArticleInputEdit;
