import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseUrl, osnSite } from "utils/constants";
import { useMagicLinkMutation } from "utils/commonApi/authApi";
import { Link } from "react-router-dom";

const LoginForm = ({ email, setEmail, showLogin }) => {
  console.log(baseUrl);
  const [getMagicLink, { isLoading }] = useMagicLinkMutation();

  const handleMagicLink = async () => {
    if (!email) {
      return toast.warning("Fill out the email box to continue");
    }

    const { data } = await getMagicLink({ email });

    if (data?.statusCode === 404) {
      return toast.error(data?.message);
    }
    if (data?.statusCode === 200) {
      return toast.success(data?.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const email = e.target.email.value;
    const body = {
      password,
      email,
    };

    try {
      const response = await axios.post(`${baseUrl}/auth/login/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = response.data;

      if (result?.user?.statusCode === 401) {
        return toast.error(result?.user?.message);
      } else if (result?.user?.success) {
        // const token = response?.headers?.Authorization?.split(" ")[1];

        localStorage.setItem("token", result?.token);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error sending data:", error);
    }
  };

  return (
    <div className="mx-auto max-w-md px-4">
      <Helmet>
        <title>{"Login"}</title>
      </Helmet>
      <div className="text-4xl font-bold text-center">Login</div>
      <div className="font-normal text-sm text-center mt-4">
        Login to osn portal
      </div>
      <form onSubmit={handleSubmit} className="mt-6">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            placeholder="email"
            className="input input-bordered"
            required
            name="email"
            onBlur={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-control mt-4">
          <label className="label">
            <span className="label-text">Password</span>
          </label>
          <input
            type="password"
            placeholder="password"
            className="input input-bordered"
            required
            name="password"
          />
        </div>
        <div className="form-control mt-6">
          <button className="btn btn-primary w-full">Login</button>
        </div>
        <div className="form-control mt-4">
          <button
            onClick={() => handleMagicLink()}
            className="btn btn-secondary w-full"
            disabled={isLoading}
          >
            Login via Magic Link
          </button>
        </div>
      </form>
      <div className="flex justify-center text-sm mt-6">
        <div>Don't have an account?</div>
        <div
          className="ml-1 text-secondary cursor-pointer"
          onClick={() => showLogin(false)}
        >
          Register now
        </div>
      </div>
      <div className="flex justify-center text-sm mt-6">
        <div>Forgot password?</div>
        <div className="ml-1">
          Please proceed{" "}
          <a
            rel="noreferrer"
            className="link text-blue-500"
            href={`${osnSite}/auth/login`}
            target="_blank"
          >
            here.
          </a>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default LoginForm;
