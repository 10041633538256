import OsnInput from "components/osn/OsnInput";
import OsnTable from "components/osn/OsnTable";
import React from "react";

const OSNMainPage = () => {
  return (
    <>
      <OsnInput></OsnInput>
      <OsnTable></OsnTable>
      <div className="pb-20"></div>
    </>
  );
};

export default OSNMainPage;
