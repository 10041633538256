import ArticleInputEdit from 'components/article/ArticleInputEdit';
import React from 'react';

const ArticleEditPage = () => {
    return (
        <>
         <ArticleInputEdit></ArticleInputEdit>   
        </>
    );
};

export default ArticleEditPage;