import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllArticlesQuery } from "utils/commonApi/articleApi";

const ArticleTable = () => {
  const { data, isLoading } = useGetAllArticlesQuery();
  const navigate = useNavigate();

  console.log(data)

  if (isLoading) return <div>Loading...</div>;
  return (
    <>
      <div id="article_all" className="w-full overflow-x-auto mx-auto">
  <div className="table-responsive">
    <table className="table">
      {/* head */}
      <thead>
        <tr>
          <th className="px-4 py-2">#</th>
          <th className="px-4 py-2">Title</th>
          <th className="px-4 py-2">Published</th>
          <th className="px-4 py-2">Featured</th>
          <th className="px-4 py-2">Action</th>
        </tr>
      </thead>
      <tbody>
        {/* rows */}
        {data?.data?.map((d, i) => (
          <tr key={d?.id}>
            <td className="px-4 py-2">{i + 1}</td>
            <td className="px-4 py-2">{d?.title}</td>
            <td className="px-4 py-2">{d?.isPublished ? "Published" : "Not Published"}</td>
            <td className="px-4 py-2">{d?.isFeatured ? "YES" : "NO"}</td>
            <td className="px-4 py-2">
              <button
                onClick={() => navigate(`/article_main/edit/${d?.id}`)}
                className="btn btn-primary"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

    </>
  );
};

export default ArticleTable;
