
import { ProtectedRoute } from "utils/ProtectedRoute";

import WelcomePage from "pages/WelcomePage";
import HomePage from "pages/HomePage";
import OSNMainPage from "pages/OSNMainPage";
import FZFPage from "pages/FZFPage";
import OSNEditPage from "pages/OSNEditPage";
import LanguageStackMainPage from "pages/LanguageStackMainPage";
import LanguageStackEditPage from "pages/LanguageStackEditPage";
import TagsMainPage from "pages/TagsMainPage";
import TagsEditPage from "pages/TagsEditPage";
import ArticleMainPage from "pages/ArticleMainPage";
import ArticleEditPage from "pages/ArticleEditPage";
import CategoriesMainPage from "pages/CategoriesMainPage";
import CategoriesEditPage from "pages/CategoriesEditPage";
import SettingsPage from "pages/SettingsPage";
import VerifyUserPage from "pages/VerifyUserPage";
import { createBrowserRouter } from "react-router-dom";
import AddOpportunityPage from "pages/AddOpportunityPage";
import ApplicantsPage from "pages/ApplicantsPage";
import WaitingPage from "pages/WaitingPage";
import ManageUsersPage from "pages/ManageUsersPage";
import UnauthorizedPage from "pages/UnauthorizedPage";
import ClaimPage from "pages/ClaimPage";
import ProfileUpdatePage from "pages/ProfileUpdatePage";
import UpdateOpportunity from "pages/UpdateOpportunity";


const routes = createBrowserRouter([

  {
    path: "/login",
    element: <WelcomePage />,
  },
  {
    path: "/waiting",
    element: <ProtectedRoute>
      <WaitingPage />
    </ProtectedRoute>,
  },
  {
    path: "/unauthorized",
    element: <ProtectedRoute>
      <UnauthorizedPage />
    </ProtectedRoute>,
  },

  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <OSNMainPage />
      },
      {
        path: "/osn_main",
        element: <OSNMainPage />
      },

      {
        path: "/osn_main/edit/:osnId",
        element: <OSNEditPage />
      },
      {
        path: "/article_main",
        element: <ArticleMainPage />
      },

      {
        path: "/article_main/edit/:articleId",
        element: <ArticleEditPage />
      },
      {
        path: "/tags_main",
        element: <TagsMainPage />
      },
      {
        path: "/tags_main/edit/:id",
        element: <TagsEditPage />
      },


      {
        path: "/language_stacks_main",
        element: <LanguageStackMainPage />
      },
      {
        path: "/language_stacks_main/edit/:id",
        element: <LanguageStackEditPage />
      },
      {
        path: "/categories_main",
        element: <CategoriesMainPage />
      },
      {
        path: "/categories_main/edit/:id",
        element: <CategoriesEditPage />
      },


      {
        path: "/settings",
        element: <SettingsPage />
      },
      {
        path: "/profile/update",
        element: <ProfileUpdatePage />
      },

      {
        path: "/opportunity/:id",
        element: <AddOpportunityPage />
      },
      {
        path: "/opportunity/:opportunityId/update",
        element: <UpdateOpportunity />
      },
      {
        path: "/opportunities/:opportunityId/applicants/0",
        element: <ApplicantsPage />
      },
      {
        path: "/manage_users_main/:page",
        element:
          <ManageUsersPage />
      },
      {
        path: "/claim/:id",
        element:
          <ClaimPage />
      },


    ]
  },
  {
    path: "/verify/:token/confirmed",
    element: <VerifyUserPage />,
  },
  {
    path: "/*",
    element: <FZFPage />,
  },
]);

export default routes;
