import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "utils/constants";

const MediaJsonInput = ({
  handleAddToMediaArray,
  mediaArray,
  setMediaArray,
}) => {
  const [imgFile, setImgFile] = useState(null);

  const [media, setMedia] = useState({
    name: "",
    url: "",
    type: "YOUTUBE",
  });

  const [isAdding, setIsAdding] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMedia((prevMedia) => ({ ...prevMedia, [name]: value }));
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      };
      const response = await axios.post(`${baseUrl}/admin/upload`, formData, {
        headers,
      });

      const imgUrl = await response.data.Location;
      return imgUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleAddClick = async () => {
    setIsAdding(true);

    if (media.type === "IMAGE" && imgFile) {
      console.log(imgFile);
      const imgUrl = await uploadFile(imgFile);
      if (imgUrl) {
        if (imgUrl) {
          setMedia((prevMedia) => ({ ...prevMedia, url: imgUrl }));
          handleAddToMediaArray({ ...media, url: imgUrl });
        }
      } else {
        alert("something went wrong");
      }
    } else if (media.type === "YOUTUBE" && media?.url) {
      handleAddToMediaArray(media);
    }

    setMedia({ name: "", url: "", type: "YOUTUBE" });
    setImgFile(null);

    setIsAdding(false);
  };

  const handleRemoveClick = (index) => {
    const updatedMediaArray = [...mediaArray];
    updatedMediaArray.splice(index, 1);
    setMediaArray(updatedMediaArray);
  };

  const addDisable = !imgFile && !media.url ? true : false;

  return (
    <div className="form-control mb-5">
      <label className="label">
        <span className="label-text">Media json</span>
      </label>
  
      {mediaArray.map((media, i) => (
        <div key={i} className="flex flex-col md:flex-row items-center my-3">
          <div className="flex-grow">
            <span className="mr-5">{media.name}</span>
            <span className="mr-5">{media.type}</span>
          </div>
          {media?.type === "IMAGE" && (
            <div className="w-16 h-16 md:w-24 md:h-24 mr-5">
              <img className="w-full h-full" src={media?.url} alt="" />
            </div>
          )}
          {media?.type === "YOUTUBE" && <span>{media.url}</span>}
          <button
            type="button"
            onClick={() => handleRemoveClick(i)}
            className="btn btn-error mt-3 md:mt-0"
          >
            Remove
          </button>
        </div>
      ))}
  
      <div className="flex flex-col md:flex-row items-center justify-start">
        <input
          name="name"
          type="text"
          className="input input-bordered mr-5 mb-3 md:mb-0"
          placeholder="name"
          value={media.name}
          onChange={handleInputChange}
          disabled={isAdding}
        />
        <select
          name="type"
          className="select select-bordered mr-5 mb-3 md:mb-0"
          value={media.type}
          onChange={handleInputChange}
          disabled={isAdding}
        >
          <option value="YOUTUBE">YOUTUBE</option>
          <option value="IMAGE">IMAGE</option>
        </select>
        {media.type === "IMAGE" ? (
          <input
            name="url"
            type="file"
            className="file-input file-input-bordered w-full max-w-xs mr-5 mb-3 md:mb-0"
            onChange={(e) => setImgFile(e.target.files[0])}
            disabled={isAdding}
          />
        ) : (
          <input
            name="url"
            type="text"
            className="input input-bordered mr-5 mb-3 md:mb-0"
            placeholder="url"
            value={media.url}
            onChange={handleInputChange}
            disabled={isAdding}
          />
        )}
        <button
          className="btn btn-primary mb-3 md:mb-0"
          onClick={handleAddClick}
          disabled={isAdding || !media?.name || addDisable}
        >
          Add
        </button>
      </div>
    </div>
  );
  
};

export default MediaJsonInput;
