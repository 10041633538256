import ClaimTable from "components/claim/ClaimTable";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetAllClaimsQuery } from "utils/commonApi/claimApi";

const ClaimPage = () => {
  const { id: osnId } = useParams();

  const { data, isLoading } = useGetAllClaimsQuery(osnId);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <ClaimTable claims={data?.data}></ClaimTable>
    </div>
  );
};

export default ClaimPage;
