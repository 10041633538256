import ArticleInput from 'components/article/ArticleInput';
import ArticleTable from 'components/article/ArticleTable';
import React from 'react';

const ArticleMainPage = () => {
    return (
        <>
         <ArticleInput></ArticleInput>
         <div className="py-10">
            </div> 
         <ArticleTable></ArticleTable>  
        </>
    );
};

export default ArticleMainPage;