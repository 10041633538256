import React, { useState } from "react";
import { useGetAllCatsQuery } from "utils/commonApi/categoriesApi";

const CategoriesSelection = ({ selectedCats, setSelectedCats }) => {
  const { data: result, isLoading: catsLoading } = useGetAllCatsQuery();

  const [selectedCat, setSelectedCat] = useState("");

  const handleTagChange = (e) => {
    const catId = e.target.value;
    const catName = e.target.options[e.target.selectedIndex].text;

    if (!selectedCats.includes(catId)) {
      setSelectedCats([...selectedCats, { id: catId, name: catName }]);
      setSelectedCat(""); // Reset the selectedTag state
    }
  };

  const handleRemoveCat = (cat) => {
    const updatedCats = selectedCats.filter(
      (selectedCat) => selectedCat.id !== cat.id
    );
    setSelectedCats(updatedCats);
  };

if(catsLoading) return <h2>Loading...</h2>

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">Categories</span>
      </label>
      <div>
        <div className="border p-4 mb-4">
          {selectedCats.map((cat, index) => (
            <span
              key={index}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
            >
              {cat.name}
              <span className="ml-2 cursor-pointer" onClick={() => handleRemoveCat(cat)}>
                X
              </span>
            </span>
          ))}
        </div>

        <select
          className="select select-primary w-full max-w-xs"
          value={selectedCat}
          onChange={handleTagChange}
        >
          <option disabled value="">
            Select a Category
          </option>
          {result?.data?.map((cat) => (
            <option key={cat?.id} value={cat?.id}>
              {cat?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CategoriesSelection;
