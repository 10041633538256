import React from "react";
import { useNavigate } from "react-router-dom";

const OpportunityTable = ({ opportunities }) => {
  const navigate = useNavigate();

  return (
    <div
      id="osn_all"
      className="overflow-x-auto py-5 px-5 shadow-sm rounded-md mt-20"
    >
      <table className="table">
        {/* head */}
        <thead>
          <tr>
            <th>Title</th>
            {/* <th>Description</th> */}
            <th>Applications</th>
          </tr>
        </thead>
        <tbody>
          {opportunities?.map((o) => (
            <tr key={o?.id}>
              <th>{o?.title}</th>
              <td>
                <button
                  className="btn btn-secondary text-white"
                  onClick={() => navigate(`/opportunity/${o?.id}/update`)}
                >
                  Update
                </button>
              </td>
              <td>
                <button
                  disabled={o?.applications?.length === 0}
                  onClick={() =>
                    navigate(`/opportunities/${o?.id}/applicants/0`)
                  }
                  className="btn btn-primary"
                >
                  Applicants
                  <span className="ml-5">{o?.applications?.length}</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OpportunityTable;
