import { apiSlice } from "utils/rootApi/apiSlice";

const claimApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getAllClaims: builder.query({
            query: (osnId) => {
                const url = `/admin/${osnId}/claims/0`;
                const method = "GET";

                return {
                    url,
                    method,
                };
            },
            providesTags: ["claims"],
        }),

    }),
});

export const { useGetAllClaimsQuery } = claimApi;
