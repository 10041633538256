import React from "react";

const WaitingPage = () => {

  const handleLogout = () => {
    localStorage.removeItem("token");
    setTimeout(()=>{
      window.location.reload();
    }, 100)
  };

  return (
    <div className="bg-secondary h-screen">
      <p className="text-center text-xl text-white md:w-3/5 sm:w-11/12 mx-auto pt-20 ">
        Thanks for joining! Your request will be manually verified and you will
        get access when the verification is complete
      </p>
      <div className="w-full h-[200px] flex items-center justify-center">
        <button
          onClick={() => handleLogout()}
          className="text-white btn btn-primary"
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default WaitingPage;
