import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { osnSite } from "utils/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterForm = ({ showLogin }) => {


  return (
    <div className="text-white">
      <Helmet>
        <title>{"Login"}</title>
      </Helmet>
      <div className="text-4xl font-bold">Register</div>
      <div className="font-normal text-sm mt-4">
        {/* Content Authority with Kwegg */}
      </div>
      
      <div>
        <p className="text-xl my-4">
          Complete your user registration from{" "}
          <a
            rel="noreferrer"
            className="link text-blue-500"
            href={`${osnSite}/auth/sign-up`}

            target="_blank"
          >
            Open Source Network
          </a>
          <br />
          <br />
          After registration, Login here with registered email and password.
        </p>
        <button
          className="btn btn-secondary text-white cursor-pointer"
          onClick={() => {
            showLogin(true);
          }}
        >
          Login
        </button>
      </div>
      {/* <div className="flex text-xs mt-3">
        <div className="mr-2">Already registered?</div>
        <div
          className="text-secondary cursor-pointer"
          onClick={() => {
            showLogin(true);
          }}
        >
          Login here
        </div>
      </div> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

RegisterForm.propTypes = {
  showLogin: PropTypes.func.isRequired,
};

export default RegisterForm;
