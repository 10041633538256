import CategoriesInput from "components/categories/CategoriesInput";
import CategoriesTable from "components/categories/CategoriesTable";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAddCategoryMutation, useGetAllCatsQuery } from "utils/commonApi/categoriesApi";

const CategoriesMainPage = () => {
    const { data, isLoading } = useGetAllCatsQuery();

    const cats = data?.data;

    const [addCat, { isLoading: addingCat }] = useAddCategoryMutation();
  
    if (isLoading) {
      return <h2>loading...</h2>;
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = {
        name: e.target.name.value,
        parentId: Number(e.target.parentId.value),
      };
  
      const result = await addCat(data);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    };
  return (
    <>
      <CategoriesInput cats={cats} handleSubmit={handleSubmit} addingCat={addingCat}></CategoriesInput>
      <div className="py-10"></div>
      <CategoriesTable cats={cats}></CategoriesTable>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default CategoriesMainPage;
