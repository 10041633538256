import MyTextEditor from "components/others/MyTextEditor";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  useGetOneContributionQuery,
  useUpdateContributionMutation,
} from "utils/commonApi/contributionApi";
import { githubUrlRegex } from "utils/constants";

const UpdateOpportunity = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();

  const { data, isLoading: getting } =
    useGetOneContributionQuery(opportunityId);
  const opportunity = data?.data;
  const [content, setContent] = useState(opportunity?.description);

  const [update, { isLoading }] = useUpdateContributionMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const title = event.target.title.value;
    const description = content;

    // const githubUrl = event.target.githubUrl.value;

    // if(githubUrl){
    //   if(!githubUrlRegex.test(githubUrl)){
    //     return toast.error('You github repository link is invalid')
    //   }
    // }


    const data = {
      title,
      description,
      opportunityId,
      // githubUrl
    };

    const result = await update(data);
    if (result?.data?.success) {
      toast.success("contribution updated successfully");
    }
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm("Are you sure you want to do this?");
    if (isConfirmed) {
      const result = await update({ isDeleted: true, opportunityId });

      if (result?.data?.success) {
        navigate(`/opportunity/${opportunity.openSource?.id}`);
        toast.success("contribution deleted successfully");
      }
    }
  };

  useEffect(() => {
    setContent(opportunity?.description);

  }, [opportunity?.description]);

  if (getting) return <div>Loading...</div>;
  return (
    <div className="sm:w-full lg:w-3/5 mx-auto shadow-md border-t-1 border-secondary border-b-1 px-20 py-10 mt-20">
      <h2 className="text-2xl">Add a job opportunity</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-control mt-6">
          <label className="label">
            <span className="label-text text-white">Job title</span>
          </label>
          <input
            type="text"
            defaultValue={opportunity?.title}
            placeholder="Add a title"
            className="input input-bordered text-black"
            required
            name="title"
          />
        </div>
        {/* <div className="form-control mt-6">
          <label className="label">
            <span className="label-text text-white">Github Url</span>
          </label>
          <input
            type="text"
            defaultValue={opportunity?.githubUrl}
            placeholder="Add github url"
            className="input input-bordered text-black"
            required
            name="githubUrl"
          />
        </div> */}
        <div className="form-control mt-6">
          <label className="label">
            <span className="label-text text-white">Job Description</span>
          </label>
          <MyTextEditor
            content={content}
            setContent={setContent}
          ></MyTextEditor>
        </div>
        <div className="h-[100px]"></div>
        <div className="form-control mt-6">
          <button disabled={isLoading} className="btn btn-primary">
            update
          </button>
        </div>
      </form>

      <button
        onClick={handleDelete}
        disabled={isLoading}
        className="text-white btn bg-red-500 mt-10"
      >
        Delete
      </button>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default UpdateOpportunity;
