import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useEditCategoryMutation,
  useGetAllCatsQuery,
  useGetOneCatQuery,
} from "utils/commonApi/categoriesApi";

const CategoriesEdit = () => {
  const { id: categoryId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllCatsQuery();

  const cats = data?.data;

  const { data: dataCat, isLoading: catLoading } = useGetOneCatQuery(categoryId);
  const cat = dataCat?.data;

  const [updateCat, { isLoading: catUpdating }] = useEditCategoryMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: e.target.name.value,
      parentId: Number(e.target.parentId.value),
      categoryId,
    };



    const result = await updateCat(data);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
      navigate("/categories_main")
    }
  };

  if (catLoading || isLoading) return <h2>Loading...</h2>;
  return (
    <form
  onSubmit={handleSubmit}
  className="mx-auto w-10/12 p-5 shadow-md border-y-2 border-primary mt-20"
>
  <h2 className="text-2xl py-10">Category Edit</h2>

  <div className="flex flex-col md:flex-row items-center justify-center">
    <input
      defaultValue={cat?.name}
      name="name"
      className="input input-bordered input-primary w-full max-w-xs mb-5 md:mr-2 md:mb-0"
    />
    <select
      className="select select-bordered w-full max-w-xs md:ml-2"
      name="parentId"
      id=""
    >
      <option selected value={0}>
        none
      </option>
      {cats?.map((c) => (
        <option key={c?.id} value={c?.id}>{c?.name}</option>
      ))}
    </select>
  </div>
  <button disabled={catUpdating} className="btn btn-primary w-full mt-5">
    Submit
  </button>
</form>

  );
};

export default CategoriesEdit;
