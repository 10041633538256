import React from "react";
import { useNavigate } from "react-router-dom";

const CategoriesTable = ({ cats: categories }) => {
  const navigate = useNavigate();
  return (
    <table className="table w-10/12 mx-auto">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Category</th>
          <th scope="col">Parent</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {categories?.map((cat, i) => (
          <tr key={cat?.id}>
            <th scope="row">{i + 1}</th>

            <td>{cat?.name}</td>
            <td>{cat?.parentCategory?.name}</td>

            <td>
              <button
                onClick={() => navigate(`/categories_main/edit/${cat?.id}`)}
                type="button"
                className="btn btn-primary"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CategoriesTable;
