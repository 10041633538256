import React from "react";

const PreviewTextEditor = ({ content }) => {
  return (
    <div>
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl h-fit">
          {/* {content} */}
        </div>
      </dialog>
    </div>
  );
};

export default PreviewTextEditor;
