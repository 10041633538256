import MyTextEditor from "components/others/MyTextEditor";
import { useRef, useState } from "react";
import { useAddOsnMutation } from "utils/commonApi/osnApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagsSelection from "components/others/TagsSelection";
import CategoriesSelection from "components/others/CategoriesSelection";
import LanguageStacksSelection from "components/others/LanguageStacksSelection";
import ImageFileInput from "components/others/ImgageFileInput";
import MediaJsonInput from "components/others/MediaJsonInput";
import { useVerifyUserQuery } from "utils/commonApi/authApi";
import PreviewTextEditor from "components/modal/PreviewTextEditor";
import { projectUrlPattern } from "utils/constants";

const OsnInput = () => {
  const [previewData, setPreviewData] = useState("");
  const [featuredImageFile, setFeaturedImageFile] = useState([]);
  const [imgUrl, setImgURl] = useState("");

  const [isActive, setActive] = useState(true);
  const [isClaimed, setClaimed] = useState(false);
  const [isFeatured, setFeatured] = useState(false);
  const [isCodeQuestEnabled, setCodeQuestEnabled] = useState(false);

  const desRef = useRef(null);
  const [description, setDescription] = useState("");

  const depRef = useRef(null);
  const [deploymentInstructions, setDeploymentInstructions] = useState("");

  // configuring select tag feature
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLangs, setSelectedLangs] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [mediaArray, setMediaArray] = useState([]);

  const handleAddToMediaArray = (media) => {
    setMediaArray((prevMediaArray) => [...prevMediaArray, media]);
  };

  const [addOsn, { isLoading: osnAdding }] = useAddOsnMutation();

  const { data, isLoading } = useVerifyUserQuery();
  const user = data?.data;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalTags = selectedTags?.map((item) => Number(item.id));
    const finalCats = selectedCats?.map((item) => Number(item.id));
    const finalLangs = selectedLangs?.map((item) => Number(item.id));

    const name = e.target.title.value;
    const featuredImage = imgUrl;

    const tagline = e.target.tagline.value;
    const license = e.target.license.value;
    const projectUrl = e.target.projectUrl.value;

    if (!projectUrlPattern.test(projectUrl)) {
      return toast.error("Incorrect projectUrl");
    }

    if (!featuredImage) {
      return toast.error("Featured image is mandatory");
    }

    if (!user?.id) {
      return toast.error("something went wrong");
    }

    if (
      finalTags?.length <= 0 ||
      finalLangs?.length <= 0 ||
      finalCats?.length <= 0
    ) {
      return toast.error("Please add at least one tag, category and language");
    }

    const data = {
      name,

      featuredImage,
      tagline,
      license,
      isActive,
      isCodeQuestEnabled,
      isClaimed,
      isFeatured,
      mediaJson: JSON.stringify({ media: mediaArray }),
      description,
      deploymentInstructions,
      projectUrl,
      tags: finalTags,
      language_stacks: finalLangs,
      categories: finalCats,
      adminId: user?.id,
    };
    const result = await addOsn(data);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
    } else {
      toast.error(
        result?.error?.data?.message
          ? result?.error?.data?.message
          : "something went wrong"
      );
    }
  };

  // const handlePreview = (i) => {
  //   if (i === "des") {
  //     setPreviewData(description);
  //   } else if (i === "dep") {
  //     setPreviewData(deploymentInstructions);
  //   }
  //   document.getElementById("my_modal_4").showModal();
  // };

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="px-4 py-4 my-4 mx-auto w-full md:w-10/12 shadow-xl rounded-md border-secondary border-y-2 mb-5"
      >
        <div className="w-full mx-auto flex items-center justify-between">
          <span className="text-2xl text-black">Add open source</span>
          <a href="#osn_all" className="text-blue-500 text-md">
            View all
          </a>
        </div>
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            placeholder="name"
            className="input input-bordered"
            required
            name="title"
          />
        </div>
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Project url</span>
          </label>
          <input
            type="text"
            placeholder="Project URL"
            className="input input-bordered"
            required
            name="projectUrl"
          />
        </div>

        <div className="form-control mb-5 flex flex-col md:flex-row items-center md:space-x-10">
          <ImageFileInput
            featuredImageFile={featuredImageFile}
            setFeaturedImageFile={setFeaturedImageFile}
            setImgURl={setImgURl}
          ></ImageFileInput>
          {imgUrl && (
            <div className="w-full md:w-auto h-[200px] bg-red-500 mt-5 md:mt-0">
              <img className="w-full h-full" src={imgUrl} alt="img_normal" />
            </div>
          )}
        </div>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Tagline</span>
          </label>
          <input
            type="text"
            placeholder="tagline"
            className="input input-bordered"
            name="tagline"
            required
          />
        </div>

        <MediaJsonInput
          handleAddToMediaArray={handleAddToMediaArray}
          mediaArray={mediaArray}
          setMediaArray={setMediaArray}
        ></MediaJsonInput>

        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Description</span>
          </label>

          <MyTextEditor
            isRef={desRef}
            content={description}
            setContent={setDescription}
          ></MyTextEditor>
        </div>
        <div className="h-[100px]"></div>
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">Deployment Instruction</span>
          </label>
          <MyTextEditor
            isRef={depRef}
            content={deploymentInstructions}
            setContent={setDeploymentInstructions}
          ></MyTextEditor>
        </div>
        <div className="h-[100px]"></div>
        <div className="form-control mb-5">
          <label className="label">
            <span className="label-text">License</span>
          </label>
          <input
            name="license"
            type="text"
            className="input input-bordered"
            required
          />
        </div>
        <TagsSelection
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        ></TagsSelection>

        <CategoriesSelection
          selectedCats={selectedCats}
          setSelectedCats={setSelectedCats}
        ></CategoriesSelection>

        <LanguageStacksSelection
          selectedLangs={selectedLangs}
          setSelectedLangs={setSelectedLangs}
        ></LanguageStacksSelection>
        <div className="flex items-center py-5">
          <div className="flex items-center mr-5">
            <input
              onChange={() => setActive(!isActive)}
              name="isActive"
              type="checkbox"
              className="my-3 checkbox"
              defaultChecked={isActive}
            />

            <label className="label ml-3">
              <span className="label-text">Active</span>
            </label>
          </div>
          <div className="flex items-center mr-5">
            <input
              onChange={() => setCodeQuestEnabled(!isCodeQuestEnabled)}
              name="isCodeQuestEnabled"
              type="checkbox"
              className="my-3 checkbox"
              defaultChecked={isCodeQuestEnabled}
            />

            <label className="label ml-3">
              <span className="label-text">Quest enabled</span>
            </label>
          </div>

          <div className="flex items-center mr-5">
            <input
              onChange={() => setClaimed(!isClaimed)}
              name="isActive"
              type="checkbox"
              className="my-3 checkbox"
              defaultChecked={isClaimed}
            />

            <label className="label ml-3">
              <span className="label-text">Claimed</span>
            </label>
          </div>

          <div className="flex items-center">
            <input
              onChange={() => setFeatured(!isFeatured)}
              name="isActive"
              type="checkbox"
              className="my-3 checkbox"
              defaultChecked={isFeatured}
            />

            <label className="label ml-3">
              <span className="label-text">Featured</span>
            </label>
          </div>
        </div>

        <button disabled={osnAdding} className="btn btn-primary w-full mt-3">
          Submit
        </button>
      </form>

      <PreviewTextEditor content={previewData}></PreviewTextEditor>

      <ToastContainer></ToastContainer>
    </>
  );
};

export default OsnInput;
