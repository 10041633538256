import ImageFileInput from "components/others/ImgageFileInput";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  useGetUserQuery,
  useUpdateProfileMutation,
} from "utils/commonApi/profileApi";

const ProfileUpdatePage = () => {
  const navigate = useNavigate();
  const [featuredImageFile, setFeaturedImageFile] = useState([]);

  const [imgUrl, setImgURl] = useState("");

  const { data, isLoading } = useGetUserQuery();
  const profile = data?.data;

  const [updateProfile, { isLoading: updating }] = useUpdateProfileMutation();

  useEffect(() => {
    if (profile) {
      setImgURl(profile?.profileImage);
    }
  }, [profile]);

  if (isLoading) {
    return <>Loading..</>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const profileImage = imgUrl;
    const data = {
      name,
      profileImage,
    };
    const result = await updateProfile(data);
    if (result?.data?.success) {
      toast.success(result.data.message);
      navigate("/settings");
    } else {
      toast.error(
        result?.data?.message ? result?.data?.message : "Something went wrong"
      );
    }
  };
  return (
    <form onSubmit={handleSubmit} className="mx-auto w-10/12">
      <h2 className="text-left text-xl py-10">Update Profile</h2>
      <div className="flex flex-col mt-10 h-auto w-4/5 bg-gray-100 py-10">
        {imgUrl && (
          <div className="w-48 h-48 mb-10">
            <img className="w-full h-full " src={imgUrl} alt="img_normal" />
          </div>
        )}
        <div className="form-control h-auto flex flex-row items-center mb-5 w-3/5 ml-5">
          <ImageFileInput
            imgUrl={imgUrl}
            featuredImageFile={featuredImageFile}
            setFeaturedImageFile={setFeaturedImageFile}
            setImgURl={setImgURl}
          ></ImageFileInput>
        </div>
        <div className="form-control mb-5 w-3/5 ml-5">
          <label className="label">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            placeholder="title"
            className="input input-bordered"
            required
            name="name"
            defaultValue={profile?.name}
          />
        </div>
        <button
          disabled={updating}
          type="submit"
          className="btn btn-primary w-4/5 mx-auto mt-10"
        >
          Update
        </button>
      </div>
      <ToastContainer></ToastContainer>
    </form>
  );
};

export default ProfileUpdatePage;
