import React from "react";

const LanguageStacksInput = ({ handleSubmit, languages, languageAdding }) => {
  return (
    <form
  onSubmit={handleSubmit}
  className="mx-auto w-10/12 p-5 shadow-md border-y-2 border-primary mt-20"
>
  <h2 className="text-2xl py-10">Language Stack</h2>
  <div className="flex flex-col md:flex-row items-center">
    <input
      required
      name="name"
      type="text"
      placeholder="Type here"
      className="input input-bordered input-primary w-full max-w-xs mb-5 md:mr-5 md:mb-0"
    />
    <select
      className="select select-bordered w-full max-w-xs"
      name="parentId"
      id=""
    >
      <option selected value={0}>
        none
      </option>
      {languages?.map((c) => (
        <option key={c?.id} value={c?.id}>
          {c?.name}
        </option>
      ))}
    </select>
  </div>
  <button disabled={languageAdding} className="btn btn-primary w-full mt-5">
    Submit
  </button>
</form>

  );
};

export default LanguageStacksInput;
