import { quillFormats, quillModules } from "utils/constants";
import "./../../../src/custom.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Editor } from "@tinymce/tinymce-react";

const MyTextEditor = ({ isRef, content, setContent }) => {
  return (
    <div style={{ height: "300px" }}>
      {/* <Editor
          onEditorChange={(newValue, editor) => {
            setContent(newValue);
          }}
          value={content}
          apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            advlist_bullet_styles: "default,circle,square",
            advlist_number_styles:
              "default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        ></Editor> */}

      <ReactQuill
        modules={quillModules}
        formats={quillFormats}
        className="h-[300px]"
        theme="snow"
        value={content}
        onChange={setContent}
      />
    </div>
  );
};

export default MyTextEditor;
