import TagsInput from "components/tags/TagsInput";
import TagsTable from "components/tags/TagsTable";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAddTagMutation, useGetAllTagsQuery } from "utils/commonApi/tagsApi";

const TagsMainPage = () => {
  const { data, isLoading } = useGetAllTagsQuery();

  const tags = data?.data;

  const [addTag, { isLoading: tagAdding }] = useAddTagMutation();

  const handleSubmitTag = async (e) => {
    e.preventDefault();
    const name = e.target.tag.value;
    const body = { name };
    const result = await addTag(body);
    console.log(result);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
    }
  };

  if (isLoading) return <h3>Loading...</h3>;

  return (
    <>
      <TagsInput
        handleSubmitTag={handleSubmitTag}
        tagAdding={tagAdding}
      ></TagsInput>
      <TagsTable tags={tags}></TagsTable>
      <ToastContainer
        handleSubmitTag={handleSubmitTag}
        tagAdding={tagAdding}
      ></ToastContainer>
    </>
  );
};

export default TagsMainPage;
