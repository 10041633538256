import React from "react";

const TagsInput = ({ handleSubmitTag, tagAdding }) => {
  return (
    <form
      onSubmit={handleSubmitTag}
      className="mx-auto w-10/12 p-5 shadow-md border-y-2 border-primary mt-20"
    >
      <h2 className="text-2xl py-10">Tags</h2>

      <div className="flex items-center">
        <div className="flex-1">
          <input
            className="input input-bordered input-primary w-full max-w-xs"
            name="tag"
            required
          ></input>

          <br />
          <button disabled={tagAdding} className="btn btn-primary mt-5">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default TagsInput;
