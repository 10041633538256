import React from "react";
import { useNavigate } from "react-router-dom";

const LanguageStacksTable = ({ languages }) => {
  const navigate = useNavigate();
  return (
    <table className="table w-11/12 mx-auto mt-20">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">language</th>
          <th scope="col">Parent</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {languages?.map((lang, i) => (
          <tr key={lang?.id}>
            <th scope="row">{i + 1}</th>

            <td>{lang?.name}</td>
            <td>{lang?.parentLanguageStack?.name}</td>

            <td>
              <button
                onClick={() =>
                  navigate(`/language_stacks_main/edit/${lang?.id}`)
                }
                type="button"
                className="btn btn-primary"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LanguageStacksTable;
