import { useEffect } from "react";
import { useVerifyUserQuery } from "./commonApi/authApi";
import { useNavigate, Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { data, isLoading, isError, isSuccess } = useVerifyUserQuery();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const user = data?.data;
      if (!user) {
        navigate("/login");
      } else {
        const { role } = user;
        if (role === "NORMAL") {
          navigate("/waiting");
        } else if (role === "ADMIN" || role === "CONTENT_EDITOR") {
          if (window.location.pathname === "/manage_users_main") {
            navigate("/unauthorized");
          }
        }
      }
    }
  }, [isLoading, isSuccess, data, navigate]);

  if (isLoading) {
    return <h2>Loading...</h2>;
  }



  if (!isSuccess || !data?.data || isError) {
    return <Navigate to="/login" />;
  }

  return children;
};
