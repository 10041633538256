import MyTextEditor from "components/others/MyTextEditor";
import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAddContributionMutation } from "utils/commonApi/contributionApi";
import { githubUrlRegex } from "utils/constants";

const OpportunityInput = ({ osnId, osn }) => {
  const [content, setContent] = useState("");
  const [addOpportunity, { isLoading }] = useAddContributionMutation();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const title = event.target.title.value;
    // const githubUrl = event.target.githubUrl.value;

    // if(githubUrl){
    //   if(!githubUrlRegex.test(githubUrl)){
    //     return toast.error('You github repository link is invalid')
    //   }
    // }

    const description = content;
    const data = {
      title,
      description,
      osnId,
      // githubUrl
    };

    const result = await addOpportunity(data);
    if (result?.data?.success) {
      toast.success("contribution added successfully");
      setContent("");
      event.target.reset();
    }
  };
  return (
    <>
      <div className="mx-auto w-11/12 my-20">
        <h2 className="text-xl">OpenSource: {osn?.name}</h2>
        <h2 className="text-lg">Tagline: {osn?.tagline}</h2>
        {/* <p className="text-md">Description: {osn?.description}</p> */}
      </div>
      <form
        onSubmit={handleSubmit}
        className="sm:w-full lg:w-3/5 mx-auto shadow-md border-t-1 border-secondary border-b-1 px-20 py-10 mt-20"
      >
        <h2 className="text-2xl">Add a job opportunity</h2>

        <div className="form-control mt-6">
          <label className="label">
            <span className="label-text text-white">Job title</span>
          </label>
          <input
            type="text"
            placeholder="Add a title"
            className="input input-bordered text-black"
            required
            name="title"
          />
        </div>
        {/* <div className="form-control mt-6">
          <label className="label">
            <span className="label-text text-white">Github Url</span>
          </label>
          <input
            type="text"
            placeholder="Add github url"
            className="input input-bordered text-black"
            required
            name="githubUrl"
          />
        </div> */}
        <div className="form-control mt-6">
          <label className="label">
            <span className="label-text text-white">Job Description</span>
          </label>
          <MyTextEditor
            content={content}
            setContent={setContent}
          ></MyTextEditor>
        </div>
        <div className="h-[100px]"></div>
        <div className="form-control mt-6">
          <button disabled={isLoading} className="btn btn-primary">
            Add
          </button>
        </div>
        <ToastContainer></ToastContainer>
      </form>
    </>
  );
};

export default OpportunityInput;
