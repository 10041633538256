/* eslint-disable eqeqeq */
import { AiFillEdit, AiFillSetting } from "react-icons/ai";

// use this baseUrl for trial/staging

export const baseUrl = "https://devapi.opensources.network";
// export const baseUrl = "https://api.opensources.network";
// export const baseUrl = "http://localhost:3010";
// ----------------------------------------------------------------



// the url for user registration 
// while working locally
// export const osnSite = 'http://localhost:3001/auth/sign-up'

// while on trial
export const osnSite = 'https://devp.opensources.network'

// while on production
// export const osnSite = 'https://opensources.network' 


export const joditConfig = {
  zIndex: 0,
  readonly: false,
  activeButtonsInReadOnly: [
    'source',
    'fullSize',
    'print',
    'about',
    'dots'
  ],
  toolbarButtonSize: 'middle',
  theme: 'default',
  saveModeInCookie: true,
  spellcheck: true,
  editorCssClass: false,
  triggerChangeEvent: true,
  width: '100%',
  height: 'auto',
  minHeight: 300,
  maxHeight: 400,
  direction: '',
  language: 'auto',
  debugLanguage: false,
  i18n: 'en',
  tabIndex: -1,
  toolbar: true,
  enter: "P",
  useSplitMode: false,

  colorPickerDefaultTab: 'text',
  imageDefaultWidth: 300,
  removeButtons: [],
  disablePlugins: [],
  extraButtons: [],
  sizeLG: 500,
  sizeMD: 700,
  sizeSM: 400,
  buttons: [
    'source', '|',
    'bold',
    'strikethrough',
    'underline',
    'italic', '|',
    'ul',
    'ol', '|',
    'outdent', 'indent', '|',
    'font',
    'fontsize',
    'brush',
    'paragraph', '|',
    'image',
    'video',
    'table',
    'link', '|',
    'align', 'undo', 'redo', '|',
    'hr',
    'eraser',
    'copyformat', '|',
    'symbol',
    'fullSize',
    'print',
    'about'
  ],
  buttonsXS: [
    'bold',
    'image', '|',
    'brush',
    'paragraph', '|',
    'align', '|',
    'undo', 'redo', '|',
    'eraser',
    'dots'
  ],
  events: {},
  textIcons: false,
}


export const projectUrlPattern = /^osnHQ\/[a-zA-Z0-9_-]+$/;



export const drawerItems_admin = [
  {
    icon: <AiFillEdit />,
    label: <div className="font-poppin text-base">Open Sources</div>,
    link: "/osn_main"
  },
  {
    icon: <AiFillEdit />,
    label: <div className="font-poppin text-base">Articles</div>,
    link: "/article_main"
  },

  {
    icon: <AiFillEdit />,
    label: <div className="font-poppin text-base">Tags</div>,
    link: "/tags_main"
  },

  {
    icon: <AiFillEdit />,
    label: <div className="font-poppin text-base">Language Stacks</div>,
    link: "/language_stacks_main"
  },


  {
    icon: <AiFillEdit />,
    label: <div className="font-poppin text-base">Categories</div>,
    link: "/categories_main"
  },




  {
    icon: (
      <AiFillSetting id="sidebar-icon-aichat" />
    ),
    label: <div className="font-poppin text-base">Settings</div>,
    link: "/settings"
  },

];


export const drawerItems_superAdmin = [
  {
    icon: <AiFillEdit />,
    label: <div className="font-poppin text-base">Manage Users</div>,
    link: "/manage_users_main/1"
  },
]


export const userRoles = [
  'SUPER_ADMIN', 'ADMIN', 'CONTENT_EDITOR', 'NORMAL', 'BLOCKED'
]


export const quillModulesMain = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    [
      {
        color: ["#F00", "#0F0", "#00F", "#000", "#FFF"],
      },
    ],
    [
      {
        font: ["sans-serif", "serif", "monospace"],
      },
    ],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video",],
    ["clean"],
  ],
};

export const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { list: "check" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video",],
    ["clean"],
  ],
};

export const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
];
