import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEditTagMutation, useGetOneTagQuery } from "utils/commonApi/tagsApi";

const TagsEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetOneTagQuery(id);

  const tag = data?.data;

  const [editTag, { isLoading: editing }] = useEditTagMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;

    const body = {
      name,
      tagId: id,
    };

    const result = await editTag(body);
    if (result?.data?.success) {
      toast.success(result?.data?.message);
      navigate("/tags_main")
    }
  };

  if(isLoading) return <h2>Loading...</h2>;
  return (
    <form
      className="mx-auto w-10/12 p-5 shadow-md border-y-2 border-primary mt-20"
      onSubmit={handleSubmit}
    >
      <h2 className="text-2xl py-10">Edit Tag</h2>

      <div className="flex items-center">
        <div className="flex-1">
          <input
            defaultValue={tag?.name}
            className="input input-bordered input-primary w-full max-w-xs"
            name="name"
          ></input>
          <br />
          <button 
          disabled={editing}
          className="btn btn-primary mt-5">Submit</button>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </form>
  );
};

export default TagsEdit;
