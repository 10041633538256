import { apiSlice } from "utils/rootApi/apiSlice";

const categoriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({


    getAllCats: builder.query({
      query: () => {
        const url = `/public/categories`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["cats"],
    }),


    getOneCat: builder.query({
      query: (categoryId) => {
        const url = `/public/category/${categoryId}`;
        const method = "GET";

        return {
          url,
          method,
        };
      },
      providesTags: ["cats"],
    }),


    addCategory: builder.mutation({
      query: (body) => {
        const url = `/admin/category/create`;
        const method = "POST";


        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["cats"],
    }),

    editCategory: builder.mutation({
      query: (rqBody) => {
        const url = `/admin/category/update/${rqBody?.categoryId}`;
        const method = "PUT";

        const body = {
          name: rqBody?.name,
          parentId: rqBody?.parentId
        }

        return {
          url,
          method,
          body,
        };
      },
      invalidatesTags: ["cats"],
    }),








  }),
});

export const { useGetOneCatQuery, useGetAllCatsQuery, useAddCategoryMutation, useEditCategoryMutation } = categoriesApi;
