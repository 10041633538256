import React from "react";
import userImg from "images/parag.png";

const Description = () => {
  return (
    <div className="flex flex-col text-white font-poppin h-[inherit]">
      <div className="">
        <div className="text-4xl font-bold">Magic starts here 🧙</div>
        <div className="font-normal text-sm pt-4">
          Let's add content to OSN.
        </div>
      </div>
      <div className="">
        <div className="font-normal text-sm">
          We recommend providing clear and helpful information which is SEO
          compliant.
        </div>
        <div className="flex items-center mt-5">
          <div className="mr-2">
            <div>
              <div className="relative">
                <img className="w-10 h-10 rounded-full" src={userImg} alt="" />
                <span className="top-0 left-7 absolute w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full" />
              </div>
            </div>
          </div>
          <div className="flex flex-col text-xs">
            <div className="font-semibold">Parag Arora</div>
            <div className="font-normal">Founder, OpenSources.Network</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
